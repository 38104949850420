import { NgModule } from '@angular/core';
import { AppMainLayoutComponent } from './main.layout';

@NgModule({
  imports: [
  ],
  declarations: [
    AppMainLayoutComponent,
  ],
  exports: [
    AppMainLayoutComponent,
  ]
})
export class MainLayoutModule {}
